/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import "./index.scss"

const HomeHero = () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "header-brush-spatula.png" }) {
          childImageSharp {
            fluid (quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        logo: file(relativePath: { eq: "logo-header.png" }) {
          childImageSharp {
            fluid (quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `}
    render={data => {
      const background = data.background.childImageSharp.fluid
      const logo = data.logo.childImageSharp.fluid
      return (
        <div className="wrapper">
        <BackgroundImage
          Tag="section"
          className="home-hero"
          fluid={background}
          loading={"eager"}
          style={{
            // Defaults are overwrite-able by setting one or each of the following:
            backgroundSize: 'contain',
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="home-hero__info">
            <div className="home-hero__image">
              <Img fluid={logo} />
            </div>
            <hr className="home-hero__divider" />
            <h1>
              <strong>Get More Cells</strong>
            </h1>
          </div>
        </BackgroundImage>
        </div>
      )
    }}
  />
)

export default HomeHero
