/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import BackgroundImage from "gatsby-background-image"
import "./index.scss"

const Video = ({ ...props }) => (
  <section className="video">
    <div className="video-contain">
      <h3>{props.headline}</h3>
      <p>{props.content}</p>
      <div className="video__video">
        <iframe src={`https://www.youtube.com/embed/${props.videoID}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
      </div>
    </div>
  </section>
)

export default Video
