import React from "react"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import { graphql, StaticQuery, Link } from "gatsby"
import HomeHero from "src/components/home-hero"
import SplitCTA from "src/components/split-cta"
import Video from "src/components/video"
import Overview from "src/components/overview"

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "home-box1.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        two: file(relativePath: { eq: "home-box-discarded_cells.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        three: file(relativePath: { eq: "home-box3.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "header-spatula-container.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const two = data.two.childImageSharp.fixed
      const three = data.three.childImageSharp.fixed
      const background = data.background.childImageSharp.fixed

      return (
        <Layout>
          <SEO title="Home" />
          <HomeHero />
          <Overview
            title="Overview"
            intro="Sed ut perspicgaiatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et"
            statOne="54%"
            statOneText="reduction in unsats"
            statTwo="74%"
            statTwoText="other main stat goes here"
            image={background}
          ></Overview>
          <Video
            headline="using the exCellerator™"
            content="The process is simple. Watch this 1-minute video to learn how exCellerator-aided sample transfer might help obtaining a more complete sample for testing."
            videoID="FowBsDO9cPs"
          />
          <SplitCTA
            headline="how it works"
            staggered={false}
            image={one}
            divider={true}
          >
            <p>
              By teasing the bristles with aid of the exCellerator hole,
              clinicians can increase the amount of sample transferred into the
              vial for testing.
            </p>
            <p>
              <Link to="/demo-video/quick-reference-guide">Click here</Link> for
              a step-by-step description of how exCellerator aids clinicians in
              more complete transfer.
            </p>
            <p>
              The exCellerator is designed to simply and safely aid in more
              complete cell transfer from the cytobrush into the ThinPrep vial.
            </p>
            <h4 style={{ color: "white", fontSize: "1.25rem" }}>
              <em>a better way</em>
            </h4>
            <p>
              Instead of simply swishing the cytobrush around in the ThinPrep
              vial and then discarding, clinicians using the exCellerator™ move
              the cytobrush in and out of the hole in the exCellerator spatula.
            </p>
          </SplitCTA>
          <SplitCTA
            headline="early detection"
            staggered={true}
            image={two}
            divider={true}
          >
            <h4 style={{ color: "white", fontSize: "1.25rem" }}>
              <em>
                Longer screening cycles make it critical to get a complete
                sample and accurate screen during every patient visit.
              </em>
            </h4>
            <p>
              A woman might only have one chance every three+ years to be
              screened, so a missed abnormality can persist for an additional 3+
              years unnecessarily.
            </p>
            <p>
              Every cell matters when it comes to{" "}
              <strong>
                <em>early detection and diagnosis</em>
              </strong>
              . A more complete sample can enable the laboratory to provide
              clinicians with{" "}
              <strong>
                <em>more accurate and actionable results</em>
              </strong>
              ; which in turn equips the clinicians to provide{" "}
              <strong>
                <em>more timely and effective patient care</em>
              </strong>
              .
            </p>
          </SplitCTA>
          <SplitCTA
            headline="a better way"
            content="Use of the exCellerator is complementary to the standard ThinPrep transfer process as it simply and safely aids in capturing the valuable sample collected with the cytobrush."
            staggered={false}
            image={three}
            divider={true}
          />
        </Layout>
      )
    }}
  />
)

export default IndexPage
