/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Img from "gatsby-image"
import "./index.scss"

const Overview = ({ ...props }) => (
  <section className="wrapper">
    <div className="overview">
      <div className="overview__content-contain">
        <div className="overview__content">
          <h2 style={{ color: "rebeccaPurple" }}>
            <em>A More Complete Sample</em>
          </h2>
          <p>
            Having a{" "}
            <strong>
              <em>more complete sample</em>
            </strong>{" "}
            can impact testing accuracy and patient care.
          </p>
          <p>
            <strong>
              <em>Clinicians work hard</em>
            </strong>{" "}
            to collect a quality cervical sample, but valuable cells can remain
            behind on the cytobrush.
          </p>
          <p>
            {" "}
            <strong style={{ color: "rebeccaPurple" }}>
              <em>In one simple step</em>
            </strong>{" "}
            the exCellerator™ aids in achieving a{" "}
            <strong>
              <em>more complete transfer</em>
            </strong>{" "}
            of that valuable sample into the vial for testing.
          </p>
          {props.children}
        </div>
      </div>
      <Img
        fadeIn={false}
        loading={"eager"}
        className="overview-img"
        fluid={props.image}
      />
    </div>
  </section>
)

export default Overview
