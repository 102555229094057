/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import "./index.scss"

const SplitCTA = ({ ...props }) => {
  return (
  <section className="split-cta">
    <BackgroundImage fluid={props.image} fadeIn={true} className={"split-cta__image"  + (props.staggered ? ' staggered' : '')}></BackgroundImage>
    <div className="split-cta__content-contain">
      <div className="split-cta__content">
        {props.headline &&
          <>
            <h3>{props.headline}</h3>
            <hr className="split-cta__hr" />
          </>
        }
        <p className="split-cta__content-content">{props.content}</p>
        <p className="split-cta__content-subcontent">{props.subcontent}</p>
        {props.children && props.children}
      </div>
    </div>
  </section>
  )
}

export default SplitCTA
